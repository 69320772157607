import './App.css';
import HelloWorld from './components/HelloWorld';

function App() {
  return (
    <HelloWorld />
  );
}

export default App;
